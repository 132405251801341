import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const botId = process.env.CHAT_BOT_ID;
type ShowOptions = 'mobile' | 'all-not-mobile';

export const SiemensChatBot: React.FC<{
  showOn: ShowOptions;
  align?: string;
  hidden?: boolean;
  onOpenState?(is_open: boolean): void;
}> = ({ showOn, align = 'left', hidden = false, onOpenState }) => {
  const { i18n, t } = useTranslation();
  const botEnv: 'dev' | 'prod' = 'prod';
  const loginButton = false;
  const logLevel: 'debug' | 'info' | 'warn' | 'error' = 'info';
  const initialMessage = t('chatBot.initialMessage');
  const enableHistory = false;

  const chatBotSettings = `{
      element: '#webchat',
      botId: '${botId}',
      botEnv: '${botEnv}',
      language: "${i18n.language || 'en'}",
      loginButton: '${loginButton}',
      logLevel: '${logLevel}',
      initialMessage: '${initialMessage}',
      enableHistory: ${enableHistory},
      translationConfiguration: setupTranslationConfiguration("${botId}"),
      userLang:  "${i18n.language || 'en'}",
    }`;

  /*   if (showOn) {
    if (showOn === 'mobile' && !isMobile) {
      return null;
    }
    if (showOn === 'all-not-mobile' && isMobile) {
      return null;
    }
  } */

  useEffect(() => {
    /* bot-box */
    const observeState = () => {
      const targetElement = document?.querySelector('.bot-box');
      if (targetElement) {
        const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
              const fullScreenClass = 'fullscreen-mode';
              if ((targetElement as HTMLDivElement).style.width === '100%') {
                targetElement.classList.add(fullScreenClass);
              } else {
                targetElement.classList.remove(fullScreenClass);
              }
            }
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
              const currentClasses = targetElement.classList;
              onOpenState && onOpenState(currentClasses.contains('widget-open'));
            }
          });
        });

        // Configure the MutationObserver to observe attribute changes
        const config = { attributes: true, attributeFilter: ['class', 'style'] };
        observer.observe(targetElement, config);
      }
    };
    setTimeout(observeState, 500);
  }, [onOpenState]);

  const [hydrated, setHydrated] = React.useState(false);

  React.useEffect(() => {
    setHydrated(true);
  }, []);

  const botDivRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <Helmet>
        <script>{`function initBot() {
           new BotanicaWebchat( ${chatBotSettings});          
        }`}</script>

        {hydrated ? (
          <script src="https://botbuilder.siemens.cloud/channels/public/dist/botConfig.js" />
        ) : null}

        {hydrated ? (
          <script src="https://botbuilder.siemens.cloud/channels/public/dist/botanicaWebchat.bundle.js" />
        ) : null}
      </Helmet>
      <div
        id="botbuilder"
        className={`${showOn} ${align} ${hidden ? 'hidden' : ''}`}
        ref={botDivRef}></div>
    </>
  );
};
