/* eslint-disable */
// TODO eslint typecheck
// @ts-nocheck
import { COOKIE_CONSENT_TYPE } from '../cookie-consent';
import { CallToActionButtonProps, ModalCtaConfig, TranslationCta } from './types';
import { Button, Heading } from '@cue/atoms';
import { useCrispChat, useOnClickOutside, useResponsive } from '@cue/hooks';
import { Modal, useModal } from '@cue/organisms';
import { AnalyticsTransformer, LocalStorageUtil, Mdx } from '@cue/utility';
// import { AdhocTalkRequest } from '@cue/site/adhoc';
// import { PageParams } from '@cue/site/pageFrame';
import styled from '@emotion/styled';
import { DialogContact } from '@project/cms/modules/dialog-contact';
import { useFlag } from '@project/hooks/use-flag';
import { useResendAccess } from '@project/hooks/use-resend-access';
import { useUser } from '@project/hooks/use-user';
import { Consent } from '@project/site/core/consent/consent';
import { AdhocTalkRequest } from '@project/site/modules/adhoc-request';
import { useAdhocSettings } from '@project/site/modules/adhoc-request/use-adhoc-settings';
import { SiemensChatBot } from '@project/specific/components/siemens-chat-bot';
// import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
// TODO: This is not a provider -> Find a good place
// Refactor & find a better implementation to detect if a CallToActionButton will render somehting (than to hide everything, but the first via css)

function CallToActionButton(props: CallToActionButtonProps) {
  const { flag, style, icon, translations, onClick, className } = props;
  const isActive = useFlag(flag?.slug);
  const { i18n } = useTranslation();

  const button = React.useMemo(() => {
    switch (style) {
      case 'hero':
        return (
          <Button
            icon={icon?.component}
            onClick={() => onClick && onClick()}
            className={className}
            data-has-icon={icon?.component ? 'true' : 'false'}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
              )?.call_to_action
            }
          </Button>
        );
        break;
      case 'secondary':
        return (
          <Button
            styling="secondary"
            icon={icon?.component}
            data-has-icon={icon?.component ? 'true' : 'false'}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
              )?.call_to_action
            }
          </Button>
        );
        break;

      case 'small':
        return (
          <Button
            styling="small"
            icon={icon?.component}
            data-has-icon={icon?.component ? 'true' : 'false'}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
              )?.call_to_action
            }
          </Button>
        );
        break;

      case 'round':
        return (
          <Button
            styling="round"
            icon={icon?.component}
            data-has-icon={icon?.component ? 'true' : 'false'}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
              )?.call_to_action
            }
          </Button>
        );
        break;

      case 'side_badge':
        return (
          <Button
            styling="side_badge"
            icon={icon?.component}
            data-has-icon={icon?.component ? 'true' : 'false'}
            onClick={() => onClick && onClick()}
            className="side_badge "
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
              )?.call_to_action
            }
          </Button>
        );
        break;
      case 'qr_code':
        return (
          <div className="cue-cta-overlay-qr">
            <QRCode
              size={128}
              value={
                translations?.find(
                  (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
                )?.link
              }
            />
          </div>
        );
        break;

      default:
        return (
          <Button
            styling="default"
            icon={icon?.component}
            data-has-icon={icon?.component ? 'true' : 'false'}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
              )?.call_to_action
            }
          </Button>
        );
    }
  }, [props]);

  if (isActive === false || (flag && isActive === null)) return null;

  return button || null;
}

export const CallToActionOverlay: React.FC<
  PageParams<Queries.getPageContentByPageIdQuery> & { children: ReactNode }
> = (
  props: PageParams<Queries.getPageContentByPageIdQuery> & {
    children: ReactNode;
    hidden?: boolean;
  },
) => {
  const { isMobile } = useResponsive();
  const { setIsOpen } = useResendAccess();

  const { pageContext, hidden } = props;
  const callToActions = pageContext?.pageInfo?.callToActions || [];
  const callToActionGroup = pageContext?.pageInfo?.callToActionGroups || [];
  const cookieConsent = LocalStorageUtil.get(COOKIE_CONSENT_TYPE);

  const [groupOpen, setGroupOpen] = useState(false);
  const [chatBotOpen, setChatBotOpen] = useState(false);
  const [chatBotOpenState, setChatBotOpenState] = useState(false);

  const { user, isAuthenticated } = useUser();
  const { i18n, t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setGroupOpen(false));

  const { chat, load, setUser } = useCrispChat({
    needCookieConsent: true,
    CRISP_WEBSITE_ID: process.env.CRISP_WEBSITE_ID,
    options: { autoload: false },
  });

  useEffect(() => {
    let checkClose = false;

    function listener(ev: MouseEvent) {
      const path = ev.composedPath();
      if (groupOpen) {
        if (checkClose && dropdownRef.current && !path.includes(dropdownRef.current)) {
          setGroupOpen(false);
        }
        checkClose = true;
      } else {
        checkClose = false;
      }
    }
    if (groupOpen) {
      document.addEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [groupOpen]);

  useEffect(() => {
    chat.onChatClosed(() => {
      chat.hide();
      document.body.setAttribute('data-crisp-open', 'false');

      AnalyticsTransformer.trackEvent({
        category: 'support',
        type: 'chat',
        action: 'close',
      });
      dropdownRef?.current?.classList.remove('hide');
    });

    chat.onChatOpened(() => {
      document.body.setAttribute('data-crisp-open', 'true');
      dropdownRef?.current?.classList.add('hide');
    });
  }, []);

  useEffect(() => {
    if (chatBotOpenState) {
      dropdownRef?.current?.classList.add('hide');
    } else {
      dropdownRef?.current?.classList.remove('hide');
    }
  }, [chatBotOpenState]);

  useEffect(() => {
    if (user) {
      setUser(`${user.firstName} ${user.lastName} (${user.userId})`);
    } else {
      setUser('');
    }
  }, [user]);

  const [chatBlockedByCookieSettingsModalRef] = useModal();
  const [adHocModalRef] = useModal();
  const [contactModalRef] = useModal();

  const adhocSettings = useAdhocSettings();
  const adhocAvailable = useFlag(adhocSettings.adhocTimesFlag);

  const clickActions: Record<string, (ctaConfig: ModalCtaConfig) => void> = {
    'adhoc-modal': (ctaConfig: ModalCtaConfig) => {
      if (isAuthenticated) {
        adHocModalRef.current?.open();
      } else {
        setIsOpen({ isAdhoc: true });
      }
    },
    contact_modal: (ctaConfig: ModalCtaConfig) => {
      contactModalRef.current?.open();
    },
    'open-link': (ctaConfig: ModalCtaConfig) => {
      const link =
        ctaConfig?.translations?.find(
          (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
        )?.link ||
        ctaConfig?.translations?.find(
          (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === 'en',
        )?.link;

      if (!link) return;

      if (String(link).startsWith('http')) {
        if (ctaConfig.open_in_new_tab) {
          window && window.open(link, '_blank')?.focus();
          return;
        }
        location.href = link;
      }

      navigate(link);
    },
    chatbot: (ctaConfig: ModalCtaConfig) => {
      setChatBotOpen(true);
      if (openChatbot) openChatbot(new Event('click'));
    },
    crisp_chat: async (ctaConfig: ModalCtaConfig) => {
      if (await Consent.areAllConsentsAccepted()) {
        load();
        chat.show();
        chat.open();
        AnalyticsTransformer.trackEvent({
          category: 'support',
          type: 'chat',
          action: 'open',
        });
        return;
      }

      chatBlockedByCookieSettingsModalRef.current.open();
    },
  };

  const ctas = callToActions
    .filter((cta) => {
      if (!cta?.call_to_actions_id?.action) return false;
      if (chatBotOpenState && cta.call_to_actions_id.action === 'chatbot') {
        return false;
      }

      if (cta.call_to_actions_id.action === 'adhoc-modal') {
        if (!adhocAvailable) return false;
      }
      return true;
    })
    ?.map((cta: ModalCtaConfig, i: number) => {
      // if (!cta.is_public && !isAuthenticated) return null;
      return (
        <CallToActionButton
          key={i}
          className={`cue-cta-overlay-btn ${
            cta.call_to_actions_id.action
          }  ${cta?.call_to_actions_id.device
            ?.map((device) => `cue-cta-overlay-btn-device-${device}`)
            .join(' ')}`}
          {...cta.call_to_actions_id}
          onClick={() => {
            const clickAction = clickActions?.[cta.call_to_actions_id.action];
            if (!clickAction) {
              console.warn(`No CTA Click action defined for "${cta.call_to_actions_id.action}"`);
            }
            console.log('cta.call_to_actions_id', clickAction);
            clickAction(cta.call_to_actions_id);
          }}
        />
      );
    })
    .filter(Boolean) as React.ReactNode[] | undefined;
  const cta_groups = callToActionGroup?.map((ctag, i) => {
    return (
      <div
        onMouseLeave={() => setGroupOpen(false)}
        ref={dropdownRef}
        className={`cue-cta-overlay-group ${groupOpen ? 'cue-cta-overlay-group-open' : ''}`}>
        <div className="cue-cta-overlay-group-container">
          {
            ctag?.call_to_action_group_id?.call_to_actions
              .filter((cta) => {
                if (cta.call_to_actions_id.action === 'adhoc-modal') {
                  if (!adhocAvailable) return false;
                }
                return true;
              })
              ?.map((cta: ModalCtaConfig, i: number) => {
                // if (!cta.is_public && !isAuthenticated) return null;

                return (
                  <CallToActionButton
                    key={i}
                    className={`cue-cta-overlay-btn ${
                      cta.call_to_actions_id.action
                    }  ${cta?.call_to_actions_id.device
                      ?.map((device) => `cue-cta-overlay-btn-device-${device}`)
                      .join(' ')}`}
                    {...cta.call_to_actions_id}
                    icon={null}
                    onClick={() => {
                      const clickAction = clickActions?.[cta.call_to_actions_id.action];
                      if (!clickAction) {
                        console.warn(
                          `No CTA Click action defined for "${cta.call_to_actions_id.action}"`,
                        );
                      } else {
                        setGroupOpen(false);
                        clickAction(cta.call_to_actions_id);
                      }
                    }}
                  />
                );
              })
              .filter(Boolean) as React.ReactNode[] | undefined
          }
        </div>
        {ctag?.call_to_action_group_id && (
          <Button
            key={i}
            data-has-icon={ctag?.call_to_action_group_id?.icon?.component ? 'true' : 'false'}
            icon={ctag?.call_to_action_group_id?.icon?.component}
            onMouseOver={() => setGroupOpen(true)}
            onClick={() => isMobile && setGroupOpen(!groupOpen)}
            className={`cue-cta-overlay-btn cue-cta-overlay-btn-group ${
              groupOpen ? 'cue-cta-overlay-btn-group-active' : ''
            }  ${ctag?.call_to_action_group_id.device
              ?.map((device) => `cue-cta-overlay-btn-device-${device}`)
              .join(' ')}`}>
            {
              ctag?.call_to_action_group_id.translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language,
              )?.call_to_action
            }
          </Button>
        )}
      </div>
    );
  });

  return (
    <>
      {props.children}
      {ctas && ctas?.length > 0 ? <div className="cue-cta-overlay">{ctas}</div> : null}
      {!hidden && cta_groups && cta_groups?.length > 0 ? (
        <div className="cue-cta-overlay">{cta_groups}</div>
      ) : null}
      <Modal ref={adHocModalRef}>
        <AdhocTalkRequest />
      </Modal>
      <Modal ref={contactModalRef}>
        <DialogModal>
          <DialogContact />
        </DialogModal>
      </Modal>
      <Modal ref={chatBlockedByCookieSettingsModalRef}>
        <div className="crisp-chat-blocked-modal">
          <Heading type="h3">{t('crispchat.blocked.headline')}</Heading>
          <Mdx>{t('crispchat.blocked.message')}</Mdx>
          <a
            href="javascript:void(0)"
            onClick={() => {
              Consent.openPreferences();
              chatBlockedByCookieSettingsModalRef.current.close();
            }}>
            {t('crispchat.blocked.preferences')}
          </a>
          <br />
          <a
            href={`https://go.crisp.chat/chat/embed/?website_id=${process.env.CRISP_WEBSITE_ID}`}
            target="_blank">
            {t('crispchat.blocked.open')}
          </a>
        </div>
      </Modal>
      <SiemensChatBot
        showOn="all-not-mobile"
        align="right"
        hidden={!chatBotOpen}
        onOpenState={(is_open) => {
          console.log('CP_OPEN', is_open);
          setChatBotOpenState(is_open);
        }}
      />
    </>
  );
};

const DialogModal = styled.div`
  min-width: max(30vw, 400px);
  textarea {
    height: 200px;
  }
`;
